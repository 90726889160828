import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Widget from '~/models/widget';

@Component
export default class WidgetMixin extends Vue {
  @Prop({ type: Object, default: () => null }) data!: Widget | null;
  @Prop({ type: String }) redirectUrl!: string;
  @Prop({ type: String }) redirectText!: string;
  @Prop({ type: String }) dateFrom!: string;
  @Prop({ type: String }) dateTo!: string;
  @Prop({ type: Boolean, default: false }) noContainer!: boolean;
}
