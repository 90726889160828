
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  name: 'dn-widget-container',
})
export default class WidgetContainer extends Vue {
  @Prop({ type: String, default: '' }) className?: string;
  @Prop({ type: String, default: null }) title?: string;
  @Prop({ type: String, default: null }) description?: string;
  @Prop({ type: String, default: null }) redirectUrl?: string;
  @Prop({ type: String, default: null }) redirectText?: string;
  @Prop({ type: String, default: null }) dateFrom?: string;
  @Prop({ type: String, default: null }) dateTo?: string;
  @Prop({ type: Boolean, default: true }) isMobileRedirectButton?: boolean;
  @Prop({ type: Boolean, default: true }) container!: boolean;
}
