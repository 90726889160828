var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.componentName,_vm._b({tag:"component",staticClass:"banner block h-full overflow-hidden rounded-[15px] md:rounded-[18px] xl:rounded-[26px]",class:[
    _vm.sizesClass,
    {
      hidden: !!_vm.context,
      '!block': !!_vm.context && _vm.context === 'mobile_and_desktop',
      'max-md:block': !!_vm.context && _vm.context === 'mobile',
      'md:max-lg:block': !!_vm.context && _vm.context === 'tablet',
      'lg:block': !!_vm.context && _vm.context === 'desktop',
    },
  ]},'component',_vm.attrs,false),[_c('div',{staticClass:"relative h-full w-full shrink-0 transform overflow-hidden transition-transform duration-500",class:_vm.sizesClass},[(!_vm.second && _vm.label)?_c('p',{staticClass:"banner-label absolute top-0 right-0 z-10 flex items-center px-4 py-1.5 bg-red-01 rounded-bl-[15px] text-white font-lexend text-sm font-medium leading-5 md:!rounded-bl-[18px] 2xl:!rounded-bl-[26px] md:text-lg",class:{ '!text-lg md:!text-2xl': _vm.label.includes('18') }},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"absolute top-0 left-0 right-0 bottom-0 h-full w-full"},[_c('Img',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded || !_vm.lazy),expression:"loaded || !lazy"}],staticClass:"absolute top-0 left-0 right-0 bottom-0 h-full w-full object-cover object-center duration-200",class:_vm.imageClass,attrs:{"src":_vm.src,"alt":_vm.alt,"title":_vm.title},on:{"load":_vm.onLoaded}}),_vm._v(" "),(_vm.disabled)?_c('div',[_vm._t("disabled")],2):_vm._e()],1),_vm._v(" "),(!_vm.second && _vm.date)?_c('p',{staticClass:"absolute bottom-0 left-0 text-xs leading-4 font-semibold text-white px-4 py-1.5 bg-white-0.30 rounded-tr-[15px] md:rounded-tr-[18px] md:text-sm md:leading-5 2xl:!rounded-tr-[26px]"},[_vm._v("\n      "+_vm._s(_vm.date)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.second && _vm.label)?_c('p',{staticClass:"absolute bottom-0 left-0 flex items-center gap-2 p-3 bg-primary text-white font-base font-semibold rounded-tr-[15px] xl:text-xl xl:py-3 xl:px-6 after:flex after:w-[14px] after:h-[14px] after:bg-arrow-small-right-white after:bg-no-repeat after:bg-center after:bg-contain",class:{
        'px-6 !text-xl': _vm.bigLabel,
        'after:hidden': !_vm.arrow,
        'w-full justify-center xl:!p-3 xl:!text-lg': _vm.labelFullWidth,
        'bg-red-01': _vm.disabled,
      }},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }