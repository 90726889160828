
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import moment from 'moment';

@Component({
  name: 'dn-section-header',
})
export default class SectionHeader extends Vue {
  @Prop({ type: String, default: null }) title?: string;
  @Prop({ type: Boolean, default: false }) titleBasic!: string;
  @Prop({ type: String }) subtitle!: string;
  @Prop({ type: String }) to!: string;
  @Prop({ type: String }) toText?: string;
  @Prop({ type: String }) dateFrom?: string;
  @Prop({ type: String }) dateTo?: string;
  moment = moment;
}
