
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import Banner from '~/models/banner';
import DeviceMixin from '~/mixins/device.mixin';
import WidgetMixin from '~/mixins/widget.mixin';

@Component({
  name: 'dn-two-tile-banner',
})
export default class TwoTileBanner extends mixins(DeviceMixin, WidgetMixin) {
  @Prop({ type: Object, required: true }) data!: any;
  @Prop({ type: String, default: '' }) redirectUrl!: string;
  @Prop({ type: String, default: '' }) redirectText!: string;
  @Prop({ type: String, default: '' }) dateFrom!: string;
  @Prop({ type: String, default: '' }) dateTo!: string;
}
