import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=88d734d8"
import script from "./Banner.vue?vue&type=script&lang=ts"
export * from "./Banner.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/frontend/components/ui/img/img.vue').default})
