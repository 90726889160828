
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  name: 'dn-banner',
})
export default class Banner extends Vue {
  @Prop({ type: String, required: true, default: null }) src!: string;
  @Prop({ default: null }) to!: string | null;
  @Prop({ default: null }) title!: string | null;
  @Prop({ default: null }) alt!: string | null;
  @Prop({ type: Boolean, default: true }) lazy!: boolean;
  @Prop({ type: String, default: null }) label!: string;
  @Prop({ type: String, default: null }) date!: string;
  @Prop({ type: String, default: '' }) imageClass!: string;
  @Prop({ type: String, default: '' }) sizesClass!: string;
  @Prop({ type: Boolean, default: false }) rounded!: boolean;
  @Prop({ type: String, required: false }) context?: string;
  @Prop({ type: Boolean, default: false }) second?: boolean;
  @Prop({ type: Boolean, default: false }) bigLabel?: boolean;
  @Prop({ type: Boolean, default: true }) arrow?: boolean;
  @Prop({ type: Boolean, default: false }) labelFullWidth?: boolean;
  @Prop({ type: Boolean, default: false }) disabled?: boolean;

  private loaded = false;

  private get componentName(): string {
    return this.to ? 'a' : 'div';
  }

  private get attrs(): { href: string; alt: string | null } | null {
    return this.to ? { href: this.to, alt: this.title } : null;
  }

  private onLoaded() {
    this.loaded = true;
  }
}
